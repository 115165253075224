.privacy-policy {
  position: fixed;
  z-index: 9999;
  bottom: 0;
  left: 0;
  width: 100%;
  opacity: 0.95;
  text-align: center;
  padding: 1.5rem;
}
.privacy-policy__container {
  max-width: 1184px;
  margin: auto;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  align-content: center;
  justify-content: space-between;
}
.privacy-policy__item {
  margin: auto 0.25rem;
}
@media (max-width: 1184px) {
  .privacy-policy__item {
    width: 100%;
    margin: 0.25rem auto;
  }
  .privacy-policy__item:first-child {
    display: none;
  }
}
