.padding-x--50 {
  padding-left: 50px;
  padding-right: 50px;
}
.register-phone-number .register-phone-number__modal.ec-modal {
  z-index: 1071;
}
.register-phone-number .btn-request-new-code .btn-request-new-code__text {
  color: inherit;
}
.ec-modal-backdrop.ec-fade {
  opacity: 0;
}
.ec-modal-backdrop.ec-show {
  opacity: 0.5;
}
.ec-modal-backdrop {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1070;
  background-color: #000;
}
.phone-number__input-section {
  width: 400px;
}
.phone-number__input-section .intl-tel-input {
  width: 100%;
  margin-bottom: 0;
}
.phone-number__input-section .intl-tel-input.allow-dropdown.separate-dial-code .selected-flag {
  background-color: inherit;
}
.phone-number__input-section .intl-tel-input .flag-container:focus,
.phone-number__input-section .intl-tel-input .selected-flag:focus {
  outline: none;
}
.phone-number__input-section .intl-tel-input .selected-flag {
  border-right: 1px solid #DDE2E3;
}
.phone-number__input-section .intl-tel-input .selected-flag .iti-arrow {
  right: 13px;
  border-left-width: 5px;
  border-right-width: 5px;
  border-top-width: 5px;
}
.phone-number__input-section .intl-tel-input.allow-dropdown.separate-dial-code.iti-sdc-2 .selected-flag {
  width: 85px;
}
.phone-number__input-section .intl-tel-input.allow-dropdown.separate-dial-code.iti-sdc-2 input,
.phone-number__input-section .intl-tel-input.allow-dropdown.separate-dial-code.iti-sdc-2 input[type=text],
.phone-number__input-section .intl-tel-input.allow-dropdown.separate-dial-code.iti-sdc-2 input[type=tel] {
  padding-left: 100px;
}
.phone-number__input-section .intl-tel-input.allow-dropdown.separate-dial-code.iti-sdc-3 .selected-flag {
  width: 95px;
}
.phone-number__input-section .intl-tel-input.allow-dropdown.separate-dial-code.iti-sdc-3 input,
.phone-number__input-section .intl-tel-input.allow-dropdown.separate-dial-code.iti-sdc-3 input[type=text],
.phone-number__input-section .intl-tel-input.allow-dropdown.separate-dial-code.iti-sdc-3 input[type=tel] {
  padding-left: 110px;
}
.phone-number__input-section .intl-tel-input.allow-dropdown.separate-dial-code.iti-sdc-4 .selected-flag {
  width: 105px;
}
.phone-number__input-section .intl-tel-input.allow-dropdown.separate-dial-code.iti-sdc-4 input,
.phone-number__input-section .intl-tel-input.allow-dropdown.separate-dial-code.iti-sdc-4 input[type=text],
.phone-number__input-section .intl-tel-input.allow-dropdown.separate-dial-code.iti-sdc-4 input[type=tel] {
  padding-left: 120px;
}
.phone-number__input-section .intl-tel-input.allow-dropdown.separate-dial-code.iti-sdc-5 .selected-flag {
  width: 115px;
}
.phone-number__input-section .intl-tel-input.allow-dropdown.separate-dial-code.iti-sdc-5 input,
.phone-number__input-section .intl-tel-input.allow-dropdown.separate-dial-code.iti-sdc-5 input[type=text],
.phone-number__input-section .intl-tel-input.allow-dropdown.separate-dial-code.iti-sdc-5 input[type=tel] {
  padding-left: 130px;
}
.why-need-section .why-need-section__collapse-btn.ec-btn {
  font-size: 1rem;
}
