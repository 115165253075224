.padding-x--50 {
  padding-left: 50px;
  padding-right: 50px;
}

.register-phone-number {
  .register-phone-number__modal.ec-modal {
    z-index: 1071;
  }

  .btn-request-new-code .btn-request-new-code__text {
    color: inherit;
  }
}

// Styles for making Chameleon BS4's modals compatible with pages with BS3
.ec-modal-backdrop.ec-fade {
  opacity: 0;
}

.ec-modal-backdrop.ec-show {
  opacity: .5;
}

.ec-modal-backdrop {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1070;
  background-color: #000;
}

// Overwriting IntlTelInput's styles
.phone-number__input-section {
  width: 400px;

  .intl-tel-input {
    width: 100%;
    margin-bottom: 0;

    &.allow-dropdown.separate-dial-code {
      .selected-flag {
        background-color: inherit;
      }
    }

    .flag-container:focus,
    .selected-flag:focus {
      outline: none;
    }

    .selected-flag {
      border-right: 1px solid #DDE2E3;

      .iti-arrow {
        right: 13px;
        border-left-width: 5px;
        border-right-width: 5px;
        border-top-width: 5px;
      }
    }
  }

  .intl-tel-input.allow-dropdown.separate-dial-code {
    .set-up(@width, @padding-left) {
      .selected-flag {
        width: @width;
      }

      input,
      input[type=text],
      input[type=tel] {
        padding-left: @padding-left;
      }
    }

    &.iti-sdc-2 {
      .set-up(85px, 100px);
    }

    &.iti-sdc-3 {
      .set-up(95px, 110px);
    }

    &.iti-sdc-4 {
      .set-up(105px, 120px);
    }

    &.iti-sdc-5 {
      .set-up(115px, 130px);
    }
  }
}

.why-need-section {
  .why-need-section__collapse-btn.ec-btn {
    font-size: 1rem;
  }
}
