.flag {
	width: 20px;
	height: 14px;
	background:url(../img/flags.png) no-repeat;
	display: inline-block;
}

.flag.flag-ac { height: 10px; background-position: 0 0; }
.flag.flag-ad { background-position: -22px 0; }
.flag.flag-ae { height: 10px; background-position: -44px 0; }
.flag.flag-af { background-position: -66px 0; }
.flag.flag-ag { background-position: -88px 0; }
.flag.flag-ai { height: 10px; background-position: -110px 0; }
.flag.flag-al { height: 15px; background-position: -132px 0; }
.flag.flag-am { height: 10px; background-position: -154px 0; }
.flag.flag-ao { background-position: -176px 0; }
.flag.flag-aq { background-position: -198px 0; }
.flag.flag-ar { height: 13px; background-position: -220px 0; }
.flag.flag-as { height: 10px; background-position: -242px 0; }
.flag.flag-at { background-position: -264px 0; }
.flag.flag-au { height: 10px; background-position: -286px 0; }
.flag.flag-aw { background-position: -308px 0; }
.flag.flag-ax { height: 13px; background-position: -330px 0; }
.flag.flag-az { height: 10px; background-position: -352px 0; }
.flag.flag-ba { height: 10px; background-position: -374px 0; }
.flag.flag-bb { background-position: -396px 0; }
.flag.flag-bd { height: 12px; background-position: -418px 0; }
.flag.flag-be { height: 15px;  width: 18px; background-position: -440px 0; }
.flag.flag-bf { background-position: -460px 0; }
.flag.flag-bg { height: 12px; background-position: -482px 0; }
.flag.flag-bh { height: 12px; background-position: -504px 0; }
.flag.flag-bi { height: 12px; background-position: -526px 0; }
.flag.flag-bj { background-position: -548px 0; }
.flag.flag-bl { background-position: -570px 0; }
.flag.flag-bm { height: 10px; background-position: -592px 0; }
.flag.flag-bn { height: 10px; background-position: -614px 0; }
.flag.flag-bo { background-position: -636px 0; }
.flag.flag-bq { background-position: -658px 0; }
.flag.flag-br { background-position: -680px 0; }
.flag.flag-bs { height: 10px; background-position: -702px 0; }
.flag.flag-bt { background-position: -724px 0; }
.flag.flag-bv { height: 15px; background-position: -746px 0; }
.flag.flag-bw { background-position: -768px 0; }
.flag.flag-by { height: 10px; background-position: -790px 0; }
.flag.flag-bz { background-position: -812px 0; }
.flag.flag-ca { height: 10px; background-position: -834px 0; }
.flag.flag-cc { height: 10px; background-position: -856px 0; }
.flag.flag-cd { height: 15px; background-position: -878px 0; }
.flag.flag-cf { background-position: -900px 0; }
.flag.flag-cg { background-position: -922px 0; }
.flag.flag-ch { height: 15px;  width: 15px; background-position: -944px 0; }
.flag.flag-ci { background-position: -961px 0; }
.flag.flag-ck { height: 10px; background-position: -983px 0; }
.flag.flag-cl { background-position: -1005px 0; }
.flag.flag-cm { background-position: -1027px 0; }
.flag.flag-cn { background-position: -1049px 0; }
.flag.flag-co { background-position: -1071px 0; }
.flag.flag-cp { background-position: -1093px 0; }
.flag.flag-cr { height: 12px; background-position: -1115px 0; }
.flag.flag-cu { height: 10px; background-position: -1137px 0; }
.flag.flag-cv { height: 12px; background-position: -1159px 0; }
.flag.flag-cw { background-position: -1181px 0; }
.flag.flag-cx { height: 10px; background-position: -1203px 0; }
.flag.flag-cy { background-position: -1225px 0; }
.flag.flag-cz { background-position: -1247px 0; }
.flag.flag-de { height: 12px; background-position: -1269px 0; }
.flag.flag-dg { height: 10px; background-position: -1291px 0; }
.flag.flag-dj { background-position: -1313px 0; }
.flag.flag-dk { height: 15px; background-position: -1335px 0; }
.flag.flag-dm { height: 10px; background-position: -1357px 0; }
.flag.flag-do { background-position: -1379px 0; }
.flag.flag-dz { background-position: -1401px 0; }
.flag.flag-ea { background-position: -1423px 0; }
.flag.flag-ec { background-position: -1445px 0; }
.flag.flag-ee { height: 13px; background-position: -1467px 0; }
.flag.flag-eg { background-position: -1489px 0; }
.flag.flag-eh { height: 10px; background-position: -1511px 0; }
.flag.flag-er { height: 10px; background-position: -1533px 0; }
.flag.flag-es { background-position: -1555px 0; }
.flag.flag-et { height: 10px; background-position: -1577px 0; }
.flag.flag-eu { background-position: -1599px 0; }
.flag.flag-fi { height: 12px; background-position: -1621px 0; }
.flag.flag-fj { height: 10px; background-position: -1643px 0; }
.flag.flag-fk { height: 10px; background-position: -1665px 0; }
.flag.flag-fm { height: 11px; background-position: -1687px 0; }
.flag.flag-fo { height: 15px; background-position: -1709px 0; }
.flag.flag-fr { background-position: -1731px 0; }
.flag.flag-ga { height: 15px; background-position: -1753px 0; }
.flag.flag-gb { height: 10px; background-position: -1775px 0; }
.flag.flag-gd { height: 12px; background-position: -1797px 0; }
.flag.flag-ge { background-position: -1819px 0; }
.flag.flag-gf { background-position: -1841px 0; }
.flag.flag-gg { background-position: -1863px 0; }
.flag.flag-gh { background-position: -1885px 0; }
.flag.flag-gi { height: 10px; background-position: -1907px 0; }
.flag.flag-gl { background-position: -1929px 0; }
.flag.flag-gm { background-position: -1951px 0; }
.flag.flag-gn { background-position: -1973px 0; }
.flag.flag-gp { background-position: -1995px 0; }
.flag.flag-gq { background-position: -2017px 0; }
.flag.flag-gr { background-position: -2039px 0; }
.flag.flag-gs { height: 10px; background-position: -2061px 0; }
.flag.flag-gt { height: 13px; background-position: -2083px 0; }
.flag.flag-gu { height: 11px; background-position: -2105px 0; }
.flag.flag-gw { height: 10px; background-position: -2127px 0; }
.flag.flag-gy { height: 12px; background-position: -2149px 0; }
.flag.flag-hk { background-position: -2171px 0; }
.flag.flag-hm { height: 10px; background-position: -2193px 0; }
.flag.flag-hn { height: 10px; background-position: -2215px 0; }
.flag.flag-hr { height: 10px; background-position: -2237px 0; }
.flag.flag-ht { height: 12px; background-position: -2259px 0; }
.flag.flag-hu { height: 10px; background-position: -2281px 0; }
.flag.flag-ic { background-position: -2303px 0; }
.flag.flag-id { background-position: -2325px 0; }
.flag.flag-ie { height: 10px; background-position: -2347px 0; }
.flag.flag-il { height: 15px; background-position: -2369px 0; }
.flag.flag-im { height: 10px; background-position: -2391px 0; }
.flag.flag-in { background-position: -2413px 0; }
.flag.flag-io { height: 10px; background-position: -2435px 0; }
.flag.flag-iq { background-position: -2457px 0; }
.flag.flag-ir { height: 12px; background-position: -2479px 0; }
.flag.flag-is { height: 15px; background-position: -2501px 0; }
.flag.flag-it { background-position: -2523px 0; }
.flag.flag-je { height: 12px; background-position: -2545px 0; }
.flag.flag-jm { height: 10px; background-position: -2567px 0; }
.flag.flag-jo { height: 10px; background-position: -2589px 0; }
.flag.flag-jp { background-position: -2611px 0; }
.flag.flag-ke { background-position: -2633px 0; }
.flag.flag-kg { height: 12px; background-position: -2655px 0; }
.flag.flag-kh { height: 13px; background-position: -2677px 0; }
.flag.flag-ki { height: 10px; background-position: -2699px 0; }
.flag.flag-km { height: 12px; background-position: -2721px 0; }
.flag.flag-kn { background-position: -2743px 0; }
.flag.flag-kp { height: 10px; background-position: -2765px 0; }
.flag.flag-kr { background-position: -2787px 0; }
.flag.flag-kw { height: 10px; background-position: -2809px 0; }
.flag.flag-ky { height: 10px; background-position: -2831px 0; }
.flag.flag-kz { height: 10px; background-position: -2853px 0; }
.flag.flag-la { background-position: -2875px 0; }
.flag.flag-lb { background-position: -2897px 0; }
.flag.flag-lc { height: 10px; background-position: -2919px 0; }
.flag.flag-li { height: 12px; background-position: -2941px 0; }
.flag.flag-lk { height: 10px; background-position: -2963px 0; }
.flag.flag-lr { height: 11px; background-position: -2985px 0; }
.flag.flag-ls { background-position: -3007px 0; }
.flag.flag-lt { height: 12px; background-position: -3029px 0; }
.flag.flag-lu { height: 12px; background-position: -3051px 0; }
.flag.flag-lv { height: 10px; background-position: -3073px 0; }
.flag.flag-ly { height: 10px; background-position: -3095px 0; }
.flag.flag-ma { background-position: -3117px 0; }
.flag.flag-mc { height: 15px;  width: 19px; background-position: -3139px 0; }
.flag.flag-md { height: 10px; background-position: -3160px 0; }
.flag.flag-me { height: 10px; background-position: -3182px 0; }
.flag.flag-mf { background-position: -3204px 0; }
.flag.flag-mg { background-position: -3226px 0; }
.flag.flag-mh { height: 11px; background-position: -3248px 0; }
.flag.flag-mk { height: 10px; background-position: -3270px 0; }
.flag.flag-ml { background-position: -3292px 0; }
.flag.flag-mm { background-position: -3314px 0; }
.flag.flag-mn { height: 10px; background-position: -3336px 0; }
.flag.flag-mo { background-position: -3358px 0; }
.flag.flag-mp { height: 10px; background-position: -3380px 0; }
.flag.flag-mq { background-position: -3402px 0; }
.flag.flag-mr { background-position: -3424px 0; }
.flag.flag-ms { height: 10px; background-position: -3446px 0; }
.flag.flag-mt { background-position: -3468px 0; }
.flag.flag-mu { background-position: -3490px 0; }
.flag.flag-mv { background-position: -3512px 0; }
.flag.flag-mw { background-position: -3534px 0; }
.flag.flag-mx { height: 12px; background-position: -3556px 0; }
.flag.flag-my { height: 10px; background-position: -3578px 0; }
.flag.flag-mz { background-position: -3600px 0; }
.flag.flag-na { background-position: -3622px 0; }
.flag.flag-nc { height: 10px; background-position: -3644px 0; }
.flag.flag-ne { height: 15px; width: 18px; background-position: -3666px 0; }
.flag.flag-nf { height: 10px; background-position: -3686px 0; }
.flag.flag-ng { height: 10px; background-position: -3708px 0; }
.flag.flag-ni { height: 12px; background-position: -3730px 0; }
.flag.flag-nl { background-position: -3752px 0; }
.flag.flag-no { height: 15px; background-position: -3774px 0; }
.flag.flag-np { height: 15px; width: 13px; background-position: -3796px 0; }
.flag.flag-nr { height: 10px; background-position: -3811px 0; }
.flag.flag-nu { height: 10px; background-position: -3833px 0; }
.flag.flag-nz { height: 10px; background-position: -3855px 0; }
.flag.flag-om { height: 10px; background-position: -3877px 0; }
.flag.flag-pa { background-position: -3899px 0; }
.flag.flag-pe { background-position: -3921px 0; }
.flag.flag-pf { background-position: -3943px 0; }
.flag.flag-pg { height: 15px; background-position: -3965px 0; }
.flag.flag-ph { height: 10px; background-position: -3987px 0; }
.flag.flag-pk { background-position: -4009px 0; }
.flag.flag-pl { height: 13px; background-position: -4031px 0; }
.flag.flag-pm { background-position: -4053px 0; }
.flag.flag-pn { height: 10px; background-position: -4075px 0; }
.flag.flag-pr { background-position: -4097px 0; }
.flag.flag-ps { height: 10px; background-position: -4119px 0; }
.flag.flag-pt { background-position: -4141px 0; }
.flag.flag-pw { height: 13px; background-position: -4163px 0; }
.flag.flag-py { height: 11px; background-position: -4185px 0; }
.flag.flag-qa { height: 8px; background-position: -4207px 0; }
.flag.flag-re { background-position: -4229px 0; }
.flag.flag-ro { background-position: -4251px 0; }
.flag.flag-rs { background-position: -4273px 0; }
.flag.flag-ru { background-position: -4295px 0; }
.flag.flag-rw { background-position: -4317px 0; }
.flag.flag-sa { background-position: -4339px 0; }
.flag.flag-sb { height: 10px; background-position: -4361px 0; }
.flag.flag-sc { height: 10px; background-position: -4383px 0; }
.flag.flag-sd { height: 10px; background-position: -4405px 0; }
.flag.flag-se { height: 13px; background-position: -4427px 0; }
.flag.flag-sg { background-position: -4449px 0; }
.flag.flag-sh { height: 10px; background-position: -4471px 0; }
.flag.flag-si { height: 10px; background-position: -4493px 0; }
.flag.flag-sj { height: 15px; background-position: -4515px 0; }
.flag.flag-sk { background-position: -4537px 0; }
.flag.flag-sl { background-position: -4559px 0; }
.flag.flag-sm { height: 15px; background-position: -4581px 0; }
.flag.flag-sn { background-position: -4603px 0; }
.flag.flag-so { background-position: -4625px 0; }
.flag.flag-sr { background-position: -4647px 0; }
.flag.flag-ss { height: 10px; background-position: -4669px 0; }
.flag.flag-st { height: 10px; background-position: -4691px 0; }
.flag.flag-sv { height: 12px; background-position: -4713px 0; }
.flag.flag-sx { background-position: -4735px 0; }
.flag.flag-sy { background-position: -4757px 0; }
.flag.flag-sz { background-position: -4779px 0; }
.flag.flag-ta { height: 10px; background-position: -4801px 0; }
.flag.flag-tc { height: 10px; background-position: -4823px 0; }
.flag.flag-td { background-position: -4845px 0; }
.flag.flag-tf { background-position: -4867px 0; }
.flag.flag-tg { height: 13px; background-position: -4889px 0; }
.flag.flag-th { background-position: -4911px 0; }
.flag.flag-tj { height: 10px; background-position: -4933px 0; }
.flag.flag-tk { height: 10px; background-position: -4955px 0; }
.flag.flag-tl { height: 10px; background-position: -4977px 0; }
.flag.flag-tm { background-position: -4999px 0; }
.flag.flag-tn { background-position: -5021px 0; }
.flag.flag-to { height: 10px; background-position: -5043px 0; }
.flag.flag-tr { background-position: -5065px 0; }
.flag.flag-tt { height: 12px; background-position: -5087px 0; }
.flag.flag-tv { height: 10px; background-position: -5109px 0; }
.flag.flag-tw { background-position: -5131px 0; }
.flag.flag-tz { background-position: -5153px 0; }
.flag.flag-ua { background-position: -5175px 0; }
.flag.flag-ug { background-position: -5197px 0; }
.flag.flag-um { height: 11px; background-position: -5219px 0; }
.flag.flag-un { background-position: -5241px 0; }
.flag.flag-us { height: 11px; background-position: -5263px 0; }
.flag.flag-uy { background-position: -5285px 0; }
.flag.flag-uz { height: 10px; background-position: -5307px 0; }
.flag.flag-va { height: 15px; width: 15px; background-position: -5329px 0; }
.flag.flag-vc { background-position: -5346px 0; }
.flag.flag-ve { background-position: -5368px 0; }
.flag.flag-vg { height: 10px; background-position: -5390px 0; }
.flag.flag-vi { background-position: -5412px 0; }
.flag.flag-vn { background-position: -5434px 0; }
.flag.flag-vu { height: 12px; background-position: -5456px 0; }
.flag.flag-wf { background-position: -5478px 0; }
.flag.flag-ws { height: 10px; background-position: -5500px 0; }
.flag.flag-xk { height: 15px; background-position: -5522px 0; }
.flag.flag-ye { background-position: -5544px 0; }
.flag.flag-yt { background-position: -5566px 0; }
.flag.flag-za { background-position: -5588px 0; }
.flag.flag-zm { background-position: -5610px 0; }
.flag.flag-zw { height: 10px; background-position: -5632px 0; }
